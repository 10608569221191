// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('@client-side-validations/client-side-validations')

require("jquery")
require("easy-autocomplete")
// import("packs/municipals")
// import flatpickr from "flatpickr"
// require("flatpicker/dist/flatpicker.css")

// document.addEventListener("turbolinks:load", () => {
//   flatpicker("[data-behavior='flatpicker']", {
//     altInput: true,
//     altFormat: "j F Y",
//     dateFormat: "Y-m-d"
//   })
// })
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
